.stack-shadow-2,
.hover\:stack-shadow-2:hover {
    box-shadow: 1px 1px var(--tw-ring-offset-color),
                2px 2px var(--tw-ring-offset-color);
}

.stack-shadow-3,
.hover\:stack-shadow-3:hover {
    box-shadow: 1px 1px var(--tw-ring-offset-color),
                2px 2px var(--tw-ring-offset-color),
                3px 3px var(--tw-ring-offset-color);
}

.stack-shadow-4,
.hover\:stack-shadow-4:hover {
    box-shadow: 1px 1px var(--tw-ring-offset-color),
                2px 2px var(--tw-ring-offset-color),
                3px 3px var(--tw-ring-offset-color),
                4px 4px var(--tw-ring-offset-color);
}

.stack-shadow-5,
.hover\:stack-shadow-5:hover {
    box-shadow: 1px 1px var(--tw-ring-offset-color),
                2px 2px var(--tw-ring-offset-color),
                3px 3px var(--tw-ring-offset-color),
                4px 4px var(--tw-ring-offset-color),
                5px 5px var(--tw-ring-offset-color);
}

.stack-shadow-6,
.hover\:stack-shadow-6:hover {
    box-shadow: 1px 1px var(--tw-ring-offset-color),
                2px 2px var(--tw-ring-offset-color),
                3px 3px var(--tw-ring-offset-color),
                4px 4px var(--tw-ring-offset-color),
                5px 5px var(--tw-ring-offset-color),
                6px 6px var(--tw-ring-offset-color);
}