@import "tailwindcss/base";
@import "tailwindcss/components";

@import "./fonts/Inter.css";

@import "./components/animation.css";
@import "./components/discord-preview.css";
@import "./components/fontstyle.css";
@import "./components/stackshadow.css";
@import "./components/extras.css";
@import "./components/react-md.css";
@import "./components/react-select.css";

@import "tailwindcss/utilities";
