@font-face {
    font-family: Whitney;
    font-style: light;
    font-weight: 300;
    src: url(/assets/fonts/6c6374bad0b0b6d204d8d6dc4a18d820.woff) format("woff");
}

@font-face {
    font-family: Whitney;
    font-style: normal;
    font-weight: 500;
    src: url(/assets/fonts/e8acd7d9bf6207f99350ca9f9e23b168.woff) format("woff");
}

@font-face {
    font-family: Whitney;
    font-style: medium;
    font-weight: 600;
    src: url(/assets/fonts/3bdef1251a424500c1b3a78dea9b7e57.woff) format("woff");
}

@font-face {
    font-family: Whitney;
    font-style: medium;
    font-weight: 600;
    src: url(/assets/fonts/be0060dafb7a0e31d2a1ca17c0708636.woff) format("woff");
}

@font-face {
    font-family: Whitney;
    font-style: bold;
    font-weight: 700;
    src: url(/assets/fonts/8e12fb4f14d9c4592eb8ec9f22337b04.woff) format("woff");
}

.bot-tag {
    @apply font-semibold uppercase align-bottom leading-4 flex-shrink-0 bg-[#5865f2] pl-1 ml-[6px] py-[1px] px-[2px] text-[10px] rounded-[3px];
    color: #fff !important;
    -ms-flex-negative: 0;
}

.bot-tag-verified {
    width: .9375rem;
    height: .9375rem;
    margin-left: -.25rem;
    margin-bottom: .23rem;
    display: inline-block;
}

%avatar-container {
    @apply bg-clip-padding relative rounded-[50%] bg-none;
    background-position: 50%;
}

.avatar-profile,
.avatar-small,
.avatar-xlarge,
.avatar-xsmall,
.avatar-xxlarge {
    @extend %avatar-container;
}

.avatar-large {
    @extend %avatar-container;
    @apply w-10 h-10;
    background-size: 40px 40px;
}

%scroller-thumb {
    background-color: #7289da;
    border: 3px solid #fff;
    @apply rounded-lg bg-clip-padding;
}

.scroller-wrap {
    @apply relative flex;
    min-height: 1px;

    & .scroller {
        @apply flex;
        min-height: 1px;
    }

    & .scroller.scrolling > * {
        @apply pointer-events-none;
    }

    & .scroller::-webkit-scrollbar {
        width: 14px;
    }

    & .scroller::-webkit-scrollbar-thumb {
        @extend %scroller-thumb;
    }

    & .scroller::-webkit-scrollbar-track-piece {
        @extend %scroller-thumb;
        background-color: #f3f3f3;
    }

    & .scrollbar {
        @apply bg-white pointer-events-none absolute top-0 right-0 bottom-0 flex;
        width: 14px;

        &:after {
            @apply -left-1 w-1 top-0 bottom-0 bg-white absolute;
            content: "";
        }

        & .track {
            background: #f3f3f3;
            border-radius: 4px;
            @apply m-1 flex-grow flex-col;
        }

        & .thumb {
            @apply bg-white rounded-lg absolute top-0 right-0 left-0;

            &:after {
                @apply rounded-[4px] m-[3px] absolute top-0 right-0 bottom-0 left-0;
                background-color: #7289da;
                content: "";
            }
        }
    }

    & .fade {
        & .scroller::-webkit-scrollbar-thumb {
            @apply invisible;
        }

        & .scroller::-webkit-scrollbar-track-piece {
            @apply invisible;
        }

        & .scrollbar {
            & .thumb {
                @apply transition-opacity ease-in-out duration-100;
                opacity: 0;
            }
        }
    }

    & .dark {
        & .scroller::-webkit-scrollbar-thumb {
            @apply bg-black opacity-40 border-transparent;
        }

        & .scroller::-webkit-scrollbar-track-piece {
            @apply bg-transparent border-transparent;
        }

        & .scrollbar {
            background-color: #2e3136;

            &:after {
                background-color: #2e3136;
            }

            & .track {
                @apply bg-transparent;
            }

            & .thumb {
                background-color: #2e3136;

                &:after {
                    @apply bg-black opacity-40;
                }
            }
        }
    }

    & .light {
        & .scroller::-webkit-scrollbar-thumb {
            @apply bg-black opacity-40 border-transparent;
        }

        & .scroller::-webkit-scrollbar-track-piece {
            @apply bg-transparent border-transparent;
        }

        & .scrollbar {
            background-color: #f3f3f3;

            &:after {
                background-color: #f3f3f3;
            }

            & .track {
                @apply bg-transparent;
            }

            & .thumb {
                background-color: #f3f3f3;

                &:after {
                    @apply bg-black opacity-40;
                }
            }
        }
    }
}

.scroller-wrap.fade .scroller:hover:hover::-webkit-scrollbar-thumb,
.scroller-wrap.fade .scroller:hover:hover::-webkit-scrollbar-track-piece,
.scroller-wrap.fade:hover:hover::-webkit-scrollbar-thumb,
.scroller-wrap.fade:hover:hover::-webkit-scrollbar-track-piece {
    visibility: visible;
}

.scroller-wrap.fade .scroller:hover .scrollbar .thumb,
.scroller-wrap.fade:hover .scrollbar .thumb {
    opacity: 1;
}

.scroller-wrap.polyfill {
    & .scroller {
        padding-right: 14px;
    }
}

.compact {
    & .bot-tag {
        @apply m-0 mr-[3px];
    }
}

%webkit-chatpart {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
}

%webkit-chatpart-dir {
    -webkit-box-direction: normal;
}

%chat-link-decor {
    -webkit-transition: 0.05s;
    text-decoration: none;
}

.embed-title-url {
    color: rgb(0, 104, 224) !important;
}

.chat {
    @extend %webkit-chatpart;
    @extend %webkit-chatpart-dir;
    @apply flex flex-col relative text-white;

    & > .title-wrap {
        & > .title {
            @extend %webkit-chatpart-dir;
        }
    }

    & .content {
        @apply bg-white;
    }

    & .user-name {
        @apply cursor-pointer;

        & :hover {
            @apply underline;
        }
    }

    & .avatar-large {
        @apply cursor-pointer opacity-100 transition-opacity duration-200;

        &:hover {
            @apply opacity-80;
        }
    }

    & > .title-wrap {
        & .topic {
            & a {
                @extend %chat-link-decor;
            }
        }
    }

    & .messages-wrapper {
        @apply relative flex flex-1 z-0 overflow-hidden;

        & .scroller-wrap {
            @apply overflow-hidden;
        }
    }

    & .messages {
        @apply m-0 overflow-x-hidden;
    }
}

%code-message {
    @apply font-mono bg-[#f9f9f9];
}

.username-wrapper {
    @apply justify-center items-center;
}

.message-group {
    @apply break-words flex py-[20px] px-0 ml-5 mr-[6px] border-b border-[#eceeef] box-border select-text;

    & .accessory {
        @extend %webkit-chatpart;
        @extend %webkit-chatpart-dir;
        @apply flex flex-col items-start;
    }

    & .comment {
        & .attachment {
            & .attachment-inner {
                @extend %webkit-chatpart;
            }
        }
    }

    &.compact {
        & .comment {
            @apply w-full;
        }

        & .accessory {
            @apply pl-[100px];
        }

        & .emoji {
            @apply w-4 h-4 align-top;

            & .jumboable {
                @apply w-4 h-4;
            }
        }

        & .message-group {
            @apply py-[3px] px-0;
        }

        & .timestamp {
            @apply text-[#d0d8dd] inline-block w-[65px] text-right pr-[5px] overflow-hidden text-[11px] leading-4 align-bottom;
        }

        & .message {
            @apply p-0 my-[6px] mx-0 leading-[18px] align-bottom;

            &:not(.first) {
                & .timestamp {
                    color: transparent !important;
                }
            }

            &:hover {
                & .timestamp {
                    color: #99aab5 !important;
                }
            }

            & .message-text {
                & .markup {
                    @apply pl-[100px];
                    text-indent: -100px;

                    & .user-name {
                        @apply mr-[5px] text-base leading-4 font-semibold text-[#4f545c] break-all;
                    }

                    & .message-content {
                        @apply break-words;
                    }
                }
            }
        }
    }

    & a {
        @extend %chat-link-decor;
        @apply text-[#00b0f4] transition duration-[50ms] cursor-pointer;

        &:hover {
            @apply underline;
        }
    }

    & .message {
        & .markup {
            & a {
                unicode-bidi: bidi-override;
                direction: ltr;
            }
        }
    }

    & .comment {
        @apply flex-1 mr-5 min-w-0;

        & .markup {
            @apply text-[15px] text-[#737f8d] leading-[1.1em] whitespace-pre-wrap mt-[6px] break-words;

            & code {
                & .inline {
                    @extend %code-message;
                    @apply border-2 border-[#f3f3f3] rounded-[5px] w-[90%] box-border text-xs mt-[6px] whitespace-pre-wrap;
                    @apply w-auto h-auto p-[0.2em] rounded-[3px] text-[85%];
                    text-indent: 0;
                }
            }

            & pre {
                @extend %code-message;

                & code {
                    @apply bg-[#f9f9f9];
                    text-indent: 0;
                }
            }

            & strong {
                @apply font-bold;
            }

            & em {
                @apply italic;
            }
        }
    }

    &:last-of-type {
        @apply border-b-0;

        &:after {
            @apply border-b-0;
        }
    }

    & .emoji {
        @apply mb-[-5px] inline-block;
    }

    & .image {
        @apply align-bottom w-[520px];
    }

    & .avatar-large {
        @apply flex-shrink-0 mt-[-2px] mr-4;
    }

    & h2 {
        @apply m-0 p-0;

        & strong {
            @apply text-[#2e3136] text-base font-semibold tracking-normal;
        }

        & .timestamp {
            @apply text-[#99aab5] text-xs tracking-normal font-medium normal-case ml-[6px];
        }
    }

    & .highlight-separator {
        @apply opacity-0 w-0 inline-block text-[#99aab5];

        & .right-pad {
            text-indent: -10px;
        }

        & .left-pad {
            text-indent: 10px;
        }
    }
}

.hide-overflow {
    overflow: hidden;
}

.emoji {
    @apply object-contain w-[22px] h-[22px];
    margin: 0 0.05em 0 0.1em !important;
    vertical-align: -0.4em;

    & jumboable {
        @apply w-8 h-8;
    }
}

.image {
    @apply inline-block relative select-text;
}

.embed {
    @apply flex flex-col py-2 px-[10px] box-border rounded-tl-none rounded-tr-[3px] rounded-br-[3px] rounded-bl-none;
    background: hsla(0, 0%, 98%, 0.3);
    border: 1px solid hsla(0, 0%, 80%, 0.3);

    & .embed-content {
        @apply flex mb-[10px] w-full;

        & .embed-content-inner {
            @apply flex-1;

            & > :last-child {
                margin-bottom: 0 !important;
            }
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    & .embed-rich {
        @apply flex relative rounded-tl-none rounded-tr-[3px] rounded-br-[3px] rounded-bl-none;

        & .embed-rich-thumb {
            @apply max-w-[80px] max-h-[80px] rounded-[3px] w-auto object-contain flex-shrink-0 ml-5;
        }
    }

    & .embed-inner {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    & .embed-provider {
        @apply inline-block text-[#87909c] font-normal text-xs mb-[5px];
    }

    & .embed-author {
        @apply flex items-center mb-[5px];
    }

    & .embed-title {
        @apply inline-block font-semibold mb-1 text-sm;

        & + .embed-description {
            margin-top: -3px !important;
        }
    }

    & .embed-description {
        @apply block text-sm font-medium mb-[10px] tracking-normal;
        color: rgba(79, 83, 91, 0.9);

        & .markup {
            @apply whitespace-pre-line;
            margin-top: 0 !important;
            font-size: 14px !important;
            line-height: 16px !important;

            & pre {
                max-width: 100% !important;
            }
        }
    }

    & .embed-fields {
        @apply flex flex-row flex-wrap text-[#36393e] mt-[-10px] mb-[10px];

        & .embed-field {
            flex: 0;
            @apply pt-[10px] min-w-full max-w-[506px];

            & .embed-field-inline {
                @apply flex-1 min-w-[150px];
                -ms-flex-preferred-size: auto;
                flex-basis: auto;
            }

            & .embed-field-name {
                @apply text-sm mb-1 font-semibold;
            }

            & .embed-field-value {
                @apply text-sm font-medium;
            }
        }
    }

    & .embed-author-name {
        @apply inline-block font-semibold text-sm;
        color: #4f545c !important;
    }

    & .embed-author-icon {
        @apply w-5 h-5 object-contain rounded-[50%] mr-[9px];
    }

    & .embed-thumbnail {
        @apply relative inline-block mb-[10px];

        & img {
            @apply m-0;
        }
    }

    & .embed-thumbnail-gifv {
        @apply relative inline-block;
    }

    & .embed-footer {
        @apply text-xs tracking-normal inline-block font-semibold;
        color: rgba(79, 83, 91, 0.6);
    }

    & .embed-footer-icon {
        @apply mr-[10px] w-[18px] h-[18px] object-contain float-left rounded-[2.45px];
    }

    & div {
        & .embed-title {
            @apply text-[#4f545c];
        }
    }

    & .embed-inline {
        @apply p-0 my-1 mx-0 rounded-[3px];
    }

    & .image,
    & video {
        @apply flex overflow-hidden rounded-sm;
    }
}

.embed-wrapper {
    @apply flex relative mt-[5px] w-[520px];

    & .embed-color-pill {
        @apply w-1 rounded-tl-[3px] rounded-tr-none rounded-br-none rounded-bl-[3px] flex-shrink-0;
        background: #cacbce;
    }
}

.comment {
    & :last-child {
        & .embed {
            @apply mb-auto;
        }
    }
}

.flex-horizontal {
    @apply flex flex-row;

    & > .flex-spacer {
        @apply min-w-[1px];
    }
}

.flex-vertical {
    @apply flex flex-col;

    & > .flex-spacer {
        @apply min-w-[1px];
    }
}

.flex-spacer {
    @apply flex-1;
}

.flex-center {
    @apply flex justify-center items-center;
}

.theme-dark {
    & .chat {
        @apply bg-[#36393e];

        & > .content {
            @apply bg-[#36393e];
        }
    }
    & .hljs {
        @apply block overflow-x-auto p-2 bg-[#002b36] text-[#839496];
    }

    & .hljs-emphasis {
        @apply italic;
    }

    & .hljs-strong {
        @apply font-bold;
    }

    & .hljs-comment,
    & .hljs-quote {
        color: #586e75;
    }

    & .hljs-addition,
    & .hljs-keyword,
    & .hljs-selector-tag {
        color: #859900;
    }

    & .hljs-doctag,
    & .hljs-literal,
    & .hljs-meta .hljs-meta-string,
    & .hljs-number,
    & .hljs-regexp,
    & .hljs-string {
        color: #2aa198;
    }

    & .hljs-title {
        color: #268bd2;
    }

    & .embed-title-url {
        color: rgb(0, 175, 244) !important;
    }

    & .hljs-attr,
    & .hljs-attribute,
    & .hljs-class .hljs-title,
    & .hljs-template-variable,
    & .hljs-type,
    & .hljs-variable {
        color: #b58900;
    }

    & .hljs-bullet,
    & .hljs-link,
    & .hljs-meta,
    & .hljs-meta .hljs-keyword,
    & .hljs-selector-attr,
    & .hljs-selector-pseudo,
    & .hljs-subst,
    & .hljs-symbol {
        color: #cb4b16;
    }

    & .hljs-built_in,
    & .hljs-deletion {
        color: #dc322f;
    }

    & .hljs-formula {
        background: #073642;
    }

    & .message-group {
        & h2 {
            & strong {
                @apply text-white;
            }

            & span {
                color: hsla(0, 0%, 100%, 0.2);
            }
        }

        & a {
            @apply text-[#0096d1];
        }

        & .comment {
            & .markup {
                color: hsla(0, 0%, 100%, 0.7);

                & pre {
                    @apply bg-[#2e3136] border-[#282b30];

                    & code {
                        @apply bg-[#2e3136];
                    }
                }

                & code {
                    & .inline {
                        @apply bg-[#2e3136];
                    }
                }
            }
        }

        &.compact {
            & .message {
                & .markup {
                    & .user-name {
                        @apply text-[#f9f9f9];
                    }
                }
            }

            & .timestamp {
                color: hsla(0, 0%, 100%, 0.2);
            }
        }

        & .embed-wrapper {
            & .embed-color-pill {
                @apply bg-[#4f545c];
            }
        }

        & .embed {
            background-color: rgba(46, 48, 54, 0.3);
            border-color: rgba(46, 48, 54, 0.6);

            & .embed-footer,
            & .embed-provider {
                color: hsla(0, 0%, 100%, 0.6);
            }

            & .embed-author-name {
                color: #fff !important;
            }

            & div {
                & .embed-title {
                    @apply text-white;
                }
            }

            & .embed-description {
                color: hsla(0, 0%, 100%, 0.6);
            }

            & .embed-fields {
                color: hsla(0, 0%, 100%, 0.6);
                & .embed-field-name {
                    @apply text-white;
                }
            }
        }
    }

    & .messages-wrapper {
        @apply bg-[#36393e];
    }
}

.theme-light {
    & .hljs {
        @apply block overflow-x-auto p-2 bg-[#fdf6e3] text-[#657b83];
    }

    & .hljs-emphasis {
        @apply italic;
    }

    & .hljs-strong {
        @apply font-bold;
    }

    & .hljs-comment,
    & .hljs-quote {
        @apply text-[#586e75];
    }

    & .hljs-addition,
    & .hljs-keyword,
    & .hljs-selector-tag {
        @apply text-[#859900];
    }

    & .hljs-doctag,
    & .hljs-literal,
    & .hljs-meta .hljs-meta-string,
    & .hljs-number,
    & .hljs-regexp,
    & .hljs-string {
        color: #2aa198;
    }

    & .hljs-name,
    & .hljs-section,
    & .hljs-selector-class,
    & .hljs-selector-id,
    & .hljs-title {
        color: #268bd2;
    }

    & .hljs-attr,
    & .hljs-attribute,
    & .hljs-class .hljs-title,
    & .hljs-template-variable,
    & .hljs-type,
    & .hljs-variable {
        color: #b58900;
    }

    & .hljs-bullet,
    & .hljs-link,
    & .hljs-meta,
    & .hljs-meta .hljs-keyword,
    & .hljs-selector-attr,
    & .hljs-selector-pseudo,
    & .hljs-subst,
    & .hljs-symbol {
        color: #cb4b16;
    }

    & .hljs-built_in,
    & .hljs-deletion {
        color: #dc322f;
    }

    & .hljs-formula {
        background: #073642;
    }
}

.discord-view {
    @apply border-[1px] border-[#5d6575];

    & body,
    & button,
    & input,
    & textarea {
        font-family: Whitney, Helvetica Neue, Helvetica, Arial, sans-serif;
    }

    & ol,
    & ul {
        @apply list-none;
    }

    & strong {
        @apply font-semibold;
    }

    & button {
        @apply border-0 cursor-pointer;

        &:focus {
            outline: 0;
        }
    }

    & code {
        @apply font-mono text-sm leading-4;
    }

    & a {
        @apply no-underline text-[#00b0f4];

        & img {
            @apply border-none;
        }
    }
}

/* -- colors -- */

.blurple {
    color: #7289da;
}

.bg-blurple {
    background-color: #7289da;
}

.red {
    color: #f04747;
}

.bg-red {
    background-color: #f04747;
}

.bg-discord-light {
    background-color: #ffffff;
}

.bg-discord-dark {
    background-color: #36393e;
}

/* -- colors -- */

/* -- box shadow -- */

.shadow-1 {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.shadow-2 {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.shadow-3 {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.4);
}
.shadow-4 {
    box-shadow: 0 28px 50px rgba(0, 0, 0, 0.4);
}

/* -- box shadow -- */

/* -- hover -- */

[class*="shadow-hover"] {
    transition: box-shadow 0.5s ease;
}
.shadow-hover-1:hover,
.shadow-hover-1:focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.shadow-hover-2:hover,
.shadow-hover-2:focus {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.shadow-hover-3:hover,
.shadow-hover-3:focus {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.4);
}
.shadow-hover-4:hover,
.shadow-hover-4:focus {
    box-shadow: 0 28px 50px rgba(0, 0, 0, 0.4);
}

.shadow-up-hover {
    position: relative;
    top: 0;
    transition: box-shadow 0.1s ease, top 0.1s ease;
}
.shadow-up-hover:hover,
.shadow-up-hover:active {
    top: -0.1rem;
}

/* -- hover -- */

/* -- misc -- */

.open-sans {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.whitney {
    font-family: Whitney, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

/*!
Atom One Dark by Daniel Gamage
Original One Dark Syntax theme from https://github.com/atom/one-dark-syntax
*/

.atom-one-dark {
    display: block !important;
    overflow-x: auto !important;
    padding: 0.5em !important;
    color: #abb2bf !important;
    background: #282c34 !important;

    & > .hljs-comment,
    & > .hljs-quote {
        color: #5c6370;
        font-style: italic;
    }

    & > .hljs-doctag,
    & > .hljs-keyword,
    & > .hljs-formula {
        color: #c678dd;
    }

    & > .hljs-section,
    & > .hljs-name,
    & > .hljs-selector-tag,
    & > .hljs-deletion,
    & > .hljs-subst {
        color: #e06c75;
    }

    & > .hljs-literal {
        color: #56b6c2;
    }

    & > .hljs-string,
    & > .hljs-regexp,
    & > .hljs-addition,
    & > .hljs-attribute,
    & > .hljs-meta-string {
        color: #98c379;
    }

    & > .hljs-built_in,
    & > .hljs-class .hljs-title {
        color: #e6c07b;
    }

    & > .hljs-attr,
    & > .hljs-variable,
    & > .hljs-template-variable,
    & > .hljs-type,
    & > .hljs-selector-class,
    & > .hljs-selector-attr,
    & > .hljs-selector-pseudo,
    & > .hljs-number {
        color: #d19a66;
    }

    & > .hljs-symbol,
    & > .hljs-bullet,
    & > .hljs-link,
    & > .hljs-meta,
    & > .hljs-selector-id,
    & > .hljs-title {
        color: #61aeee;
    }

    & > .hljs-emphasis {
        font-style: italic;
    }

    & > .hljs-strong {
        font-weight: bold;
    }

    & > .hljs-link {
        text-decoration: underline;
    }
}

/*!
Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax
*/

.atom-one-light {
    display: block !important;
    overflow-x: auto !important;
    padding: 0.5em !important;
    color: #383a42 !important;
    background: #fafafa !important;

    & > .hljs-comment,
    & > .hljs-quote {
        color: #a0a1a7;
        font-style: italic;
    }

    & > .hljs-doctag,
    & > .hljs-keyword,
    & > .hljs-formula {
        color: #a626a4;
    }

    & > .hljs-section,
    & > .hljs-name,
    & > .hljs-selector-tag,
    & > .hljs-deletion,
    & > .hljs-subst {
        color: #e45649;
    }

    & > .hljs-literal {
        color: #0184bb;
    }

    & > .hljs-string,
    & > .hljs-regexp,
    & > .hljs-addition,
    & > .hljs-attribute,
    & > .hljs-meta-string {
        color: #50a14f;
    }

    & > .hljs-built_in,
    & > .hljs-class .hljs-title {
        color: #c18401;
    }

    & > .hljs-attr,
    & > .hljs-variable,
    & > .hljs-template-variable,
    & > .hljs-type,
    & > .hljs-selector-class,
    & > .hljs-selector-attr,
    & > .hljs-selector-pseudo,
    & > .hljs-number {
        color: #986801;
    }

    & > .hljs-symbol,
    & > .hljs-bullet,
    & > .hljs-link,
    & > .hljs-meta,
    & > .hljs-selector-id,
    & > .hljs-title {
        color: #4078f2;
    }

    & > .hljs-emphasis {
        font-style: italic;
    }

    & > .hljs-strong {
        font-weight: bold;
    }

    & > .hljs-link {
        text-decoration: underline;
    }
}

/* -- highlight.js -- */
