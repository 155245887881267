.border-t-3 {
    border-top-width: 3px;
}

.role-accent-red {
    @apply border-red-500 dark:border-red-400;
}

.role-accent-yellow {
    @apply border-yellow-500 dark:border-yellow-400;
}

.role-accent-green {
    @apply border-green-500 dark:border-green-400;
}

.role-accent-blue {
    @apply border-blue-500 dark:border-blue-400;
}

.role-accent-indigo {
    @apply border-indigo-500 dark:border-indigo-400;
}

.role-accent-purple {
    @apply border-purple-500 dark:border-purple-400;
}

.role-accent-pink {
    @apply border-pink-500 dark:border-pink-400;
}

.skeleton-loader {
    @apply bg-gray-200 dark:bg-gray-600;
    @apply !bg-gradient-to-r from-gray-200 via-gray-50 to-gray-200 dark:from-gray-600 dark:via-gray-500 dark:to-gray-600;
}

/*
    Custom Skeleton styling
*/
.skeleton-loader {
    background-image: linear-gradient(90deg, #E5E7EB, #F3F4F6, #E5E7EB) !important;
    background-color: #E5E7EB !important;
}

.dark .skeleton-loader {
    background-image: linear-gradient(90deg, #4b5563, #6B7280, #4b5563) !important;
    background-color: #4b5563 !important;
}

/*
    Dark stuff
*/

@layer components {
    .form-darkable {
        @apply form-input rounded-lg bg-white border-gray-300 dark:bg-gray-800 dark:text-gray-300 dark:border-[#6B7280] hover:border-yellow-500 dark:hover:border-yellow-500 focus:border-yellow-500 dark:focus:border-yellow-500 border-[1px] transition duration-200 focus:outline-none;
        box-shadow: none !important;
    }

    .form-textarea-darkable {
        @apply form-textarea rounded-lg bg-white border-gray-300 dark:bg-gray-800 dark:text-gray-300 dark:border-[#6B7280] hover:border-yellow-500 dark:hover:border-yellow-500 focus:border-yellow-500 dark:focus:border-yellow-500 border-[1px] transition duration-200 focus:outline-none;
        box-shadow: none !important;
    }

    .form-select-darkable {
        @apply form-select rounded-lg bg-white border-gray-300 dark:bg-gray-800 dark:text-gray-300 dark:border-[#6B7280] hover:border-yellow-500 dark:hover:border-yellow-500 focus:border-yellow-500 dark:focus:border-yellow-500 border-[1px] transition duration-200 focus:outline-none;
        box-shadow: none !important;
    }
}
