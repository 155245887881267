.fade-in-modal {
    @apply opacity-100;
    animation: fadeInModalAni 300ms ease-out 1;
    transform: scale(1)
}

@keyframes fadeInModalAni {
    from {
        @apply opacity-0;
        transform: scale(1.1)
    }
    to {
        @apply opacity-100;
        transform: scale(1)
    }
}