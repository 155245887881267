/* This is a styling for <ReactMarkdown />, using TailwindCSS and postcss-nested (broken) */

.react-md {
    @apply whitespace-pre-wrap;
    @apply py-2 px-2;

    & h1 {
        @apply text-3xl font-extrabold -mb-2;
    }
    
    & h2 {
        @apply text-2xl font-extrabold -mb-2;
    }
    
    & h3 {
        @apply text-xl font-bold -mb-2;
    }
    
    & h4 {
        @apply text-lg font-semibold -mb-2;
    }
    
    & h5 {
        @apply text-base font-medium -mb-2;
    }
    
    & h6 {
        @apply text-sm font-medium -mb-2;
    }
    
    & ul {
        @apply list-disc list-inside whitespace-normal -mt-4;
    }
    
    & ol {
        @apply list-decimal list-inside whitespace-normal -mt-3;
    }
    
    & p {
        @apply whitespace-normal -my-1;
    }
    
    & a {
        @apply text-yellow-600 hover:opacity-70 duration-200;
    }

    & ul li ul,
    & ul li ol {
        @apply mt-0 ml-4;
    }
}